import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";
import PaymentMethodDataTable from "./PaymentMethodDataTable";

export default function ListPaymentMethod() {
  const dispatch = useDispatch();
  const logoRef = useRef(null);
  const { register, setValue, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      payment_method: {
        logo: "",
        name: "",
        code: "",
        type: "",
        active: true,
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  const [paymentMethodData, setPaymentMethodData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [typeModalPaymentMethod, setTypeModalPaymentMethod] =
    useState("CREATE");
  const [showModalPaymentMethod, setShowModalPaymentMethod] = useState(false);

  const typeOptions = [
    {
      label: "Ewallet",
      value: "EWALLET",
    },
    {
      label: "Virtual Account",
      value: "VA",
    },
    {
      label: "QRIS",
      value: "QRIS",
    },
  ];

  /**
   * Fetch Data
   */

  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(`${api.v1.admin.payment_method.main}`, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setPaymentMethodData(records);

      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.get(
        `${api.v1.admin.payment_method.main}/${id}`
      );

      const { logo, name, code, type, active } = data.data;

      setImageSrc(logo);
      fetch(logo).then(async res => {
        const blob = await res.blob();
        const file = new File([blob], "logo.jpg", { type: blob.type });

        setValue("payment_method.id", id);
        setValue("payment_method.name", name);
        setValue("payment_method.code", code);
        setValue("payment_method.type", type);
        setValue("payment_method.logo", [file]);
        setValue("payment_method.active", active ? true : false);
      });

      setTypeModalPaymentMethod("UPDATE");
      setShowModalPaymentMethod(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { payment_method } = payload;

      const formData = new FormData();
      formData.append("name", payment_method.name);
      formData.append("code", payment_method.code);
      formData.append("type", payment_method.type);
      formData.append("active", payment_method.active ? 1 : 0);
      formData.append("logo", payment_method.logo[0]);

      await http.post(api.v1.admin.payment_method.main, formData);

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data Metode Pembayaran berhasil di tambahkan",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { payment_method } = payload;

      const formData = new FormData();
      formData.append("name", payment_method.name);
      formData.append("code", payment_method.code);
      formData.append("type", payment_method.type);
      formData.append("active", payment_method.active ? 1 : 0);
      formData.append("logo", payment_method.logo[0]);

      await http.put(
        `${api.v1.admin.payment_method.main}/${payment_method.id}`,
        formData
      );

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data Metode Pembayaran berhasil di perbarui",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.payment_method.main}/${id}`);

      Swal.fire("Sukses", "Data Metode Pembelian di hapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpenModal = () => {
    setTypeModalPaymentMethod("CREATE");
    setShowModalPaymentMethod(true);
    setValue("payment_method.type", typeOptions[0].value);
  };

  const handleCloseModal = () => {
    setShowModalPaymentMethod(false);
    setImageSrc();
    reset();
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus metode pembayaran",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  const registerLogoField = register("payment_method.logo", {
    required: "Cover tidak boleh kosong",
  });
  const onUploadLogo = () => logoRef.current.click();
  const onLogoChange = e => {
    if (e.target.files) {
      setValue("payment_method.logo", e.target.files);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Metode Pembayaran</title>
      </Helmet>

      <DefaultHeader section="Data Metode Pembayaran" />

      <Container maxWidth={false} component={Box} id="payment-method">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal()}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <PaymentMethodDataTable
                      loading={loadingData}
                      data={paymentMethodData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Payment Method */}
                <Dialog
                  open={showModalPaymentMethod}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Data Metode Pembayaran</h2>
                    </Box>
                    <form
                      onSubmit={
                        typeModalPaymentMethod === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Nama</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("payment_method.name")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Kode</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("payment_method.code")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-type-label">
                                Tipe Pembayaran
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-type-label"
                                value={
                                  watch("payment_method.type") ||
                                  typeOptions[0].value
                                }
                                {...register("payment_method.type")}
                              >
                                {typeOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                          <FormGroup>
                            <FormLabel>Aktif</FormLabel>
                            <Switch
                              color="primary"
                              checked={watch("payment_method.active")}
                              onChange={e =>
                                setValue(
                                  "payment_method.active",
                                  e.target.checked
                                )
                              }
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4} xs={8}>
                          <FormGroup>
                            <FormLabel>Logo</FormLabel>
                            <Button
                              variant="contained"
                              onClick={() => onUploadLogo()}
                            >
                              {typeModalPaymentMethod === "CREATE"
                                ? "Tambah Logo"
                                : "Ubah Logo"}
                            </Button>
                            <input
                              style={{ display: "none" }}
                              onChange={e => {
                                registerLogoField.onChange(e);
                                onLogoChange(e);
                              }}
                              accept="image/*"
                              type="file"
                              ref={logoRef}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item sm={8} xs={4}></Grid>
                        <Grid item sm={12} className="mb-4">
                          {imageSrc && (
                            <Box
                              component="img"
                              sx={{
                                // height: 240,
                                // width: 420,
                                maxHeight: { xs: 240, md: 240 },
                                maxWidth: { xs: 240, md: 420 },
                              }}
                              alt="Logo."
                              src={imageSrc}
                            />
                          )}
                        </Grid>

                        <Grid item sm={12} xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalPaymentMethod === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
