import React, { useEffect, useState } from "react";

import {
  Container,
  Box,
  Grid,
  Button,
  Card,
  Dialog,
  FormGroup,
  FormLabel,
  OutlinedInput,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  Switch,
  makeStyles,
} from "@material-ui/core";
import { useFieldArray, useForm } from "react-hook-form";
import { Add, Delete, KeyboardArrowDown, Search } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import componentStyles from "assets/theme/views/admin/elements";
import componentStylesForm from "assets/theme/components/forms";
import componentStylesAvatar from "assets/theme/components/avatar";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesValidation from "assets/theme/views/admin/validation.js";
import themeColors from "assets/theme/colors";

import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";
import SubModuleDataTable from "./SubModuleDataTable";
import ReactSelect from "react-select";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const useStyles = makeStyles(componentStyles);
const useStylesForm = makeStyles(componentStylesForm);
const useStylesAvatar = makeStyles(componentStylesAvatar);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesValidation = makeStyles(componentStylesValidation);

export default function ListSubModulePage() {
  const classes = {
    ...useStyles(),
    ...useStylesForm(),
    ...useStylesAvatar(),
    ...useStylesButtons(),
    ...useStylesValidation(),
  };

  const { courseId, moduleId } = useParams();

  const dispatch = useDispatch();
  const { register, watch, setValue, control } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "videos",
    keyName: "key",
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [subModuleData, setSubModuleData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 50 });

  const [copySubModule, setCopySubModule] = useState(false);
  const [optionCourseData, setOptionCourseData] = useState([]);
  const [optionCourseModuleData, setOptionCourseModuleData] = useState([]);
  const [optionCourseSubModuleData, setOptionCourseSubModuleData] = useState(
    []
  );

  const [typeSubModalModule, setTypeSubModalModule] = useState("CREATE");
  const [showSubModalModule, setShowSubModalModule] = useState(false);

  const subModuleTypeOptions = [
    {
      text: "Video",
      value: "VIDEO",
    },
    {
      text: "Ebook",
      value: "EBOOK",
    },
    {
      text: "Teks",
      value: "TEXT",
    },
    {
      text: "Kuis",
      value: "QUIZ",
    },
    {
      text: "Practice Assignment",
      value: "PRACTICE_ASSIGNMENT",
    },
    {
      text: "Assignment",
      value: "ASSIGNMENT",
    },
    {
      text: "Verifikasi Wajah",
      value: "FACE_RECOGNITION",
    },
    {
      text: "Jurnal Reflektif",
      value: "REFLECTIVE_JOURNAL",
    },
  ];

  const quizTypeOptions = [
    {
      text: "Pre Test",
      value: "PRE_TEST",
    },
    {
      text: "Modul Test",
      value: "MODULE_TEST",
    },
    {
      text: "Post Test",
      value: "POST_TEST",
    },
  ];

  /**
   * Fetch Data
   */
  const getOptionCourses = async () => {
    try {
      const { data } = await http.get(`${api.v1.admin.course.main}`, {
        params: { option: true },
      });

      setError(null);

      const { records } = data.data;

      const options = records.map(item => {
        return { label: item.name, value: item.id };
      });

      setOptionCourseData(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getOptionCourseModules = async courseId => {
    try {
      const { data } = await http.get(
        `${api.v1.admin.course.module(courseId)}`,
        {
          params: { option: true },
        }
      );

      setError(null);

      const { records } = data.data;

      const options = records.map(item => {
        return { label: item.title, value: item.id };
      });

      setOptionCourseModuleData(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getOptionCourseSubModules = async (courseId, courseModuleId) => {
    try {
      const { data } = await http.get(
        `${api.v1.admin.course.sub_module(courseId, courseModuleId)}`,
        {
          params: { option: true },
        }
      );

      setError(null);

      const { records } = data.data;

      const options = records.map(item => {
        return { label: item.title, value: item.id };
      });

      setOptionCourseSubModuleData(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getList = async (courseId, moduleId, page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { title } = watch("filter");

      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module`,
        {
          params: { limit, page, title },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setSubModuleData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async (courseId, moduleId, id) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${id}`
      );

      setError(null);

      setTypeSubModalModule("UPDATE");
      setShowSubModalModule(true);

      if (data.data.type === "QUIZ") {
        data.data.quiz_type = data.data.quiz.type;
        data.data.minimum_score = data.data.quiz.minimum_score;
        delete data.data.quiz;
      }

      data.data?.videos?.forEach(video => {
        append({
          resolution: video.resolution,
          type: video.type,
          url: video.url,
        });
      });

      setValue("sub_module", data.data);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async (courseId, moduleId) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const videos = watch("videos");
      const payload = { videos, ...watch("sub_module") };

      if (payload.type === "VIDEO") {
        payload.access_limit = payload.access_limit ? 1 : 0;
      }

      payload.type !== "VIDEO" && delete payload.videos;

      await http.post(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module`,
        payload
      );

      setShowSubModalModule(false);
      setValue("sub_module", {});
      setValue("videos", []);

      Swal.fire("Sukses", "Data Sub Modul berhasil di tambahkan", "success");

      getList(courseId, moduleId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCopySubModuleData = async (courseId, moduleId) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      let new_sub_module_id = watch(
        "copy_sub_module.selected_course_sub_module"
      ).value;

      await http.post(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${new_sub_module_id}/copy`
      );

      setCopySubModule(false);
      setShowSubModalModule(false);
      setValue("copy_sub_module", {});

      Swal.fire("Sukses", "Data Sub Modul berhasil di salin", "success");

      getList(courseId, moduleId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async (courseId, moduleId) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const videos = watch("videos");
      delete watch("sub_module").videos;
      const payload = { videos, ...watch("sub_module") };

      payload.type !== "VIDEO" && delete payload.videos;

      await http.put(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${payload.id}`,
        payload
      );

      setShowSubModalModule(false);
      setValue("sub_module", {});
      setValue("videos", []);

      Swal.fire("Sukses", "Data Sub Modul berhasil di perbarui", "success");

      getList(courseId, moduleId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async (courseId, moduleId, id) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${id}`
      );

      getList(courseId, moduleId);

      Swal.fire("Sukses", "Data sub modul berhasil di hapus", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postChangeOrder = async (to, subModuleID) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.post(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${subModuleID}/change-order`,
        { to }
      );

      Swal.fire("Sukses", "Urutan sub modul berhasil di ubah", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(courseId, moduleId, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList(courseId, moduleId);
  };

  const handleDeleteData = (courseId, moduleId, id) => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data sub modul",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(courseId, moduleId, id);
      }
    });
  };

  const handleChangeOrder = result => {
    if (!result.destination) return;

    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengubah urutan sub modul",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        const items = reorder(
          subModuleData,
          result.source.index,
          result.destination.index
        );

        postChangeOrder(result.destination.index, result.draggableId);

        setSubModuleData(items);
      }
    });
  };

  useEffect(() => {
    getList(courseId, moduleId);
    getOptionCourses();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Sub Modul Kelas</title>
      </Helmet>

      <DefaultHeader
        section="Data Sub Modul"
        subsection="Data Modul"
        routeBack={`/kelas/${courseId}/modul`}
      />

      <Container maxWidth={false} component={Box} id="course-sub-module-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setValue("sub_module", {});
                      setTypeSubModalModule("CREATE");

                      setValue("copy_sub_module", {});
                      setCopySubModule(false);

                      setShowSubModalModule(true);
                    }}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <Grid className="p-3 d-flex justify-content-end">
                      {/* Search by Title */}
                      <Grid sm={4}>
                        <Grid className="d-flex align-items-center justify-content-end">
                          <OutlinedInput
                            type="text"
                            placeholder="Cari berdasarkan Judul"
                            startAdornment={
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            }
                            className="mr-3"
                            onKeyPress={e =>
                              e.key === "Enter" && getList(courseId, moduleId)
                            }
                            {...register("filter.title")}
                          />

                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => getList(courseId, moduleId)}
                          >
                            <Box
                              component={Search}
                              width="1.25rem!important"
                              height="1.25rem!important"
                              position="relative"
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <SubModuleDataTable
                      loading={loadingData}
                      data={subModuleData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                      handleChangeOrder={handleChangeOrder}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Sub Module */}
                <Dialog
                  open={showSubModalModule}
                  onClose={() => {
                    setCopySubModule(false);
                    setShowSubModalModule(false);
                    setValue("videos", []);
                  }}
                  fullWidth
                  maxWidth="md"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Data Sub Modul</h2>
                    </Box>

                    <Grid container className="pt-4">
                      {typeSubModalModule === "CREATE" && (
                        <Grid item xs={12} md={8}>
                          <FormGroup>
                            <FormLabel>Salin dari sub modul</FormLabel>
                            <Switch
                              color="primary"
                              checked={copySubModule}
                              onChange={e => {
                                setValue("sub_module", {});
                                setValue("copy_sub_module", {});
                                setCopySubModule(e.target.checked);
                              }}
                            />
                          </FormGroup>
                        </Grid>
                      )}

                      {!copySubModule ? (
                        <>
                          <Grid item xs={12} md={8}>
                            <FormGroup>
                              <FormLabel>Judul</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="text"
                                {...register("sub_module.title")}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <FormGroup>
                              <FormControl variant="outlined" fullWidth>
                                <FormLabel>Tipe</FormLabel>
                                <Select
                                  IconComponent={KeyboardArrowDown}
                                  value={watch("sub_module.type") || ""}
                                  {...register("sub_module.type")}
                                >
                                  {subModuleTypeOptions.map((item, key) => (
                                    <MenuItem key={key} value={item.value}>
                                      {item.text}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </FormGroup>
                          </Grid>

                          {watch("sub_module.type") && (
                            <>
                              {["EBOOK", "VIDEO"].includes(
                                watch("sub_module.type")
                              ) && (
                                <Grid item xs={12}>
                                  <FormGroup>
                                    <FormLabel>URL</FormLabel>
                                    <OutlinedInput
                                      fullWidth
                                      type="text"
                                      {...register("sub_module.url")}
                                    />
                                  </FormGroup>
                                </Grid>
                              )}

                              {["EBOOK", "TEXT"].includes(
                                watch("sub_module.type")
                              ) && (
                                <Grid item xs={12}>
                                  <FormGroup>
                                    <FormLabel>Teks</FormLabel>
                                    <ReactQuill
                                      theme="snow"
                                      onChange={val =>
                                        setValue("sub_module.text", val)
                                      }
                                      value={watch("sub_module.text") || ""}
                                    />
                                  </FormGroup>
                                </Grid>
                              )}

                              {["PRACTICE_ASSIGNMENT", "ASSIGNMENT"].includes(
                                watch("sub_module.type")
                              ) && (
                                <>
                                  <Grid item xs={12}>
                                    <FormGroup>
                                      <FormLabel>Teks</FormLabel>
                                      <ReactQuill
                                        theme="snow"
                                        onChange={val =>
                                          setValue("sub_module.text", val)
                                        }
                                        value={watch("sub_module.text") || ""}
                                      />
                                    </FormGroup>
                                  </Grid>
                                  {watch("sub_module.type") ===
                                    "ASSIGNMENT" && (
                                    <Grid item xs={12}>
                                      <FormGroup>
                                        <FormLabel>Nilai Kompeten</FormLabel>
                                        <OutlinedInput
                                          fullWidth
                                          type="number"
                                          {...register(
                                            "sub_module.competent_score"
                                          )}
                                        />
                                      </FormGroup>
                                    </Grid>
                                  )}
                                  <Grid item xs={12} md={4}>
                                    <FormGroup>
                                      <FormLabel>Sequence</FormLabel>
                                      <OutlinedInput
                                        fullWidth
                                        type="number"
                                        {...register(
                                          "sub_module.prakerja_sequence"
                                        )}
                                      />
                                    </FormGroup>
                                  </Grid>
                                </>
                              )}

                              {watch("sub_module.type") === "VIDEO" && (
                                <>
                                  <Grid item xs={12}>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      style={{ marginBottom: "20px" }}
                                    >
                                      <FormLabel>Resolusi Video</FormLabel>
                                      <Box marginLeft="8px!important">
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          size="small"
                                          onClick={() => {
                                            append({
                                              resolution: "",
                                              type: "",
                                              url: "",
                                            });
                                          }}
                                        >
                                          <Add size="large" />
                                        </Button>
                                      </Box>
                                    </Box>

                                    {fields.map((field, index) => (
                                      <Grid container key={field.key}>
                                        <Grid item xs={12} md={3}>
                                          <FormGroup>
                                            <FormLabel>Resolusi</FormLabel>
                                            <Box display="flex">
                                              <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="720"
                                                {...register(
                                                  `videos.${index}.resolution`
                                                )}
                                              />
                                            </Box>
                                          </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                          <FormGroup>
                                            <FormLabel>Tipe</FormLabel>
                                            <Box display="flex">
                                              <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="video/mp4"
                                                {...register(
                                                  `videos.${index}.type`
                                                )}
                                              />
                                            </Box>
                                          </FormGroup>
                                        </Grid>
                                        <Grid
                                          item
                                          direction="row"
                                          xs={12}
                                          md={6}
                                        >
                                          <FormGroup>
                                            <FormLabel>Url</FormLabel>
                                            <Box display="flex">
                                              <OutlinedInput
                                                fullWidth
                                                type="text"
                                                {...register(
                                                  `videos.${index}.url`
                                                )}
                                              />
                                              {index !== 0 && (
                                                <Box
                                                  marginLeft="5px"
                                                  display="flex"
                                                  alignItems="center"
                                                >
                                                  <Button
                                                    variant="contained"
                                                    size="small"
                                                    classes={{
                                                      root: classes.buttonContainedError,
                                                    }}
                                                    onClick={() => {
                                                      remove(index);
                                                    }}
                                                  >
                                                    <Delete size="large" />
                                                  </Button>
                                                </Box>
                                              )}
                                            </Box>
                                          </FormGroup>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FormGroup>
                                      <FormLabel>Durasi</FormLabel>
                                      <OutlinedInput
                                        fullWidth
                                        type="number"
                                        {...register("sub_module.duration")}
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FormGroup>
                                      <FormLabel>Durasi (Detik)</FormLabel>
                                      <OutlinedInput
                                        fullWidth
                                        type="number"
                                        placeholder="optional"
                                        {...register(
                                          "sub_module.duration_seconds"
                                        )}
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs={12} md={8}>
                                    <FormGroup>
                                      <FormLabel>Limit Akses</FormLabel>
                                      <Switch
                                        color="primary"
                                        checked={watch(
                                          "sub_module.access_limit"
                                        )}
                                        onChange={e => {
                                          setValue(
                                            "sub_module.access_limit",
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </FormGroup>
                                  </Grid>
                                </>
                              )}

                              {watch("sub_module.type") === "QUIZ" && (
                                <>
                                  <Grid item xs={12} md={4}>
                                    <FormGroup>
                                      <FormControl variant="outlined" fullWidth>
                                        <FormLabel>Tipe Kuis</FormLabel>
                                        <Select
                                          IconComponent={KeyboardArrowDown}
                                          value={
                                            watch("sub_module.quiz_type") || ""
                                          }
                                          {...register("sub_module.quiz_type")}
                                        >
                                          {quizTypeOptions.map((item, key) => (
                                            <MenuItem
                                              key={key}
                                              value={item.value}
                                            >
                                              {item.text}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </FormGroup>
                                  </Grid>

                                  <Grid item xs={12} md={8}>
                                    <FormGroup>
                                      <FormLabel>Nilai Minimal</FormLabel>
                                      <OutlinedInput
                                        fullWidth
                                        type="number"
                                        {...register(
                                          "sub_module.minimum_score"
                                        )}
                                      />
                                    </FormGroup>
                                  </Grid>
                                </>
                              )}

                              {watch("sub_module.type") ==
                                "FACE_RECOGNITION" && (
                                <>
                                  <Grid item xs={12}>
                                    <FormGroup>
                                      <FormLabel>Teks</FormLabel>
                                      <OutlinedInput
                                        fullWidth
                                        type="text"
                                        {...register("sub_module.text")}
                                      />
                                    </FormGroup>
                                  </Grid>

                                  <Grid item xs={12} md={4}>
                                    <FormGroup>
                                      <FormLabel>Sequence</FormLabel>
                                      <OutlinedInput
                                        fullWidth
                                        type="number"
                                        {...register(
                                          "sub_module.prakerja_sequence"
                                        )}
                                      />
                                    </FormGroup>
                                  </Grid>
                                </>
                              )}
                            </>
                          )}

                          <Grid item xs={12}>
                            <FormGroup>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  typeSubModalModule === "CREATE"
                                    ? postCreateData(courseId, moduleId)
                                    : putUpdateData(courseId, moduleId);
                                }}
                              >
                                {typeSubModalModule === "CREATE"
                                  ? "Tambah"
                                  : "Perbarui"}
                              </Button>
                            </FormGroup>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} md={6}>
                            <FormGroup>
                              <FormControl variant="outlined" fullWidth>
                                <FormLabel id="option-status-promo-code-label">
                                  Pilih kelas
                                </FormLabel>
                                <ReactSelect
                                  isMulti={false}
                                  options={optionCourseData}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={val => {
                                    setValue(
                                      "copy_sub_module.selected_course",
                                      val
                                    );
                                    getOptionCourseModules(
                                      watch("copy_sub_module.selected_course")
                                        .value
                                    );
                                    setValue(
                                      "copy_sub_module.selected_course_module",
                                      {}
                                    );
                                    setValue(
                                      "copy_sub_module.selected_course_sub_module",
                                      {}
                                    );
                                    setOptionCourseSubModuleData([]);
                                  }}
                                  value={watch(
                                    "copy_sub_module.selected_course"
                                  )}
                                />
                              </FormControl>
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <FormGroup>
                              <FormControl variant="outlined" fullWidth>
                                <FormLabel id="option-status-promo-code-label">
                                  Pilih modul kelas
                                </FormLabel>
                                <ReactSelect
                                  isMulti={false}
                                  options={optionCourseModuleData}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={val => {
                                    setValue(
                                      "copy_sub_module.selected_course_module",
                                      val
                                    );
                                    getOptionCourseSubModules(
                                      watch("copy_sub_module.selected_course")
                                        .value,
                                      watch(
                                        "copy_sub_module.selected_course_module"
                                      ).value
                                    );
                                    setValue(
                                      "copy_sub_module.selected_course_sub_module",
                                      {}
                                    );
                                  }}
                                  value={watch(
                                    "copy_sub_module.selected_course_module"
                                  )}
                                />
                              </FormControl>
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <FormGroup>
                              <FormControl variant="outlined" fullWidth>
                                <FormLabel id="option-status-promo-code-label">
                                  Pilih sub modul kelas
                                </FormLabel>
                                <ReactSelect
                                  isMulti={false}
                                  options={optionCourseSubModuleData}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={val => {
                                    setValue(
                                      "copy_sub_module.selected_course_sub_module",
                                      val
                                    );
                                  }}
                                  value={watch(
                                    "copy_sub_module.selected_course_sub_module"
                                  )}
                                />
                              </FormControl>
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12}>
                            <FormGroup>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  postCopySubModuleData(courseId, moduleId);
                                }}
                              >
                                Tambah
                              </Button>
                            </FormGroup>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
