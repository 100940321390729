import React, { useState } from "react";

import {
  Box,
  Button,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
  Switch,
} from "@material-ui/core";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import moment from "moment";

import themeColors from "assets/theme/colors";

import api from "services/api";
import http from "services/http";

const ModalExportUser = ({ openModal, onClose }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      filter: {
        limit: null,
      },
    },
  });

  const [error, setError] = useState(null);
  const [useLimit, setUseLimit] = useState(false);

  /**
   * Fetch Data
   */
  const exportData = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - Data Peserta.xlsx`;

      const params = {
        ...val.filter,
      };

      if (!useLimit) params.all = true;

      const res = await http.get(`${api.v1.admin.report.user}/export`, {
        params,
        responseType: "blob",
      });

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleClose = () => {
    reset();
    onClose();
    setUseLimit(false);
  };

  return (
    <Dialog
      open={openModal}
      onClose={() => handleClose()}
      fullWidth={true}
      maxWidth="sm"
    >
      <Container className="py-4">
        <Box textAlign="center">
          <h2>Export Data Peserta</h2>
        </Box>

        <form onSubmit={handleSubmit(exportData)}>
          <Grid container className="py-4">
            {error && (
              <Grid item xs={12}>
                <Box textAlign="center" color={themeColors.error.main}>
                  {error}
                </Box>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormGroup>
                <FormLabel>Batas Export</FormLabel>
                <Switch
                  color="primary"
                  checked={useLimit}
                  onChange={e => {
                    setUseLimit(e.target.checked);
                    setValue("filter.limit", null);
                  }}
                />
              </FormGroup>
            </Grid>

            {useLimit && (
              <Grid item xs={12}>
                <FormGroup>
                  <FormLabel>Jumlah Batas Export (Data terbaru)</FormLabel>
                  <OutlinedInput
                    fullWidth
                    type="number"
                    {...register("filter.limit")}
                  />
                </FormGroup>
              </Grid>
            )}
          </Grid>
          <Grid container className="mt-2">
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Export Data Peserta
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Dialog>
  );
};

export default ModalExportUser;
