import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import {
  Group,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PostAdd,
} from "@material-ui/icons";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import { useState } from "react";

export default function LearningProgressCourseParticipantDataTable({
  loading,
  type,
  courseWebinarId,
  getDetailData,
  data,
  permissions,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const columns = {
    USER: ["", "#", "Nama", "Email", "Nomor Telepon", "Progres Belajar"],
    TPM: [
      "#",
      "Judul",
      "Sudah Submit",
      "Belum Submit",
      "Sudah Dinilai",
      "Belum Dinilai",
      "Opsi",
    ],
    UK: [
      "#",
      "Judul",
      "Sudah Submit",
      "Belum Submit",
      "Sudah Dinilai",
      "Belum Dinilai",
      "Opsi",
    ],
    FR: ["#", "Judul", "Sudah Verifikasi", "Belum Verifikasi", "Opsi"],
  };

  const subModuletypeConstant = {
    VIDEO: "Video",
    QUIZ: "Kuis",
    EBOOK: "E-Book",
    TEXT: "Text",
    PRACTICE_ASSIGNMENT: "Tugas Praktik Mandiri",
    ASSIGNMENT: "Uji Keterampilan",
    ATTENDANCE: "Kehadiran",
    ATTENDANCE_SCHEDULE: "Jadwal Kehadiran",
    FACE_RECOGNITION: "Verifikasi Wajah",
    REFLECTIVE_JOURNAL: "Jurnal Reflektif",
  };

  const [open, setOpen] = useState(false);

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns[type].map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((item, index) => {
              return (
                <>
                  {/* item header */}
                  <TableRow key={item.id}>
                    {/* type user has detail drop item */}
                    {type === "USER" ? (
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            setOpen(prev => ({
                              ...prev,
                              [item.id]: !prev[item.id],
                            }))
                          }
                        >
                          {open[item.id] ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      </TableCell>
                    ) : null}

                    <TableCell component="td" scope="row" align="center">
                      {pagination.current_page > 1
                        ? (pagination.current_page - 1) * pagination.limit +
                          (index + 1)
                        : index + 1}
                    </TableCell>

                    {/* user */}
                    {type === "USER" && (
                      <>
                        <TableCell component="td" align="center">
                          {item?.user?.name || "-"}
                        </TableCell>
                        <TableCell component="td" align="center">
                          {item?.user?.email || "-"}
                        </TableCell>
                        <TableCell component="td" align="center">
                          {item?.user?.phone_number || "-"}
                        </TableCell>
                        <TableCell component="td" align="center">
                          {item?.progress_percentage || 0}%
                        </TableCell>
                      </>
                    )}

                    {/* TPM / UK */}
                    {type === "TPM" || type === "UK" ? (
                      <>
                        <TableCell component="td" align="center">
                          {item?.title || "-"}
                        </TableCell>
                        <TableCell component="td" align="center">
                          {item?.user_submitted}
                        </TableCell>
                        <TableCell component="td" align="center">
                          {item?.user_not_submit}
                        </TableCell>
                        <TableCell component="td" align="center">
                          {item?.user_rated}
                        </TableCell>
                        <TableCell component="td" align="center">
                          {item?.user_not_yet_rated}
                        </TableCell>
                      </>
                    ) : null}

                    {/* FR */}
                    {type === "FR" && (
                      <>
                        <TableCell component="td" align="center">
                          {item?.title || "-"}
                        </TableCell>
                        <TableCell component="td" align="center">
                          {item?.user_verified}
                        </TableCell>
                        <TableCell component="td" align="center">
                          {item?.user_not_verified}
                        </TableCell>
                      </>
                    )}

                    {type !== "USER" && (
                      <TableCell component="td" align="center">
                        {permissions?.includes("281-3") ? (
                          <>
                            <IconButton
                              onClick={() =>
                                getDetailData(
                                  item?.module?.course_id,
                                  item?.module?.id,
                                  item?.id,
                                  type,
                                  courseWebinarId,
                                  true
                                )
                              }
                            >
                              <Group />
                            </IconButton>
                            {["TPM", "UK"].includes(type) && (
                              <IconButton
                                onClick={() => {
                                  getDetailData(
                                    item?.module?.course_id,
                                    item?.module?.id,
                                    item?.id,
                                    type,
                                    courseWebinarId
                                  );
                                }}
                              >
                                <PostAdd />
                              </IconButton>
                            )}
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    )}
                  </TableRow>

                  {/* drop menu detail *type user only */}
                  {type === "USER" && open[item.id] ? (
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={open[item.id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Grid container className="mb-2">
                            <Grid item xs={12} md={6} component={Box}>
                              <List dense disablePadding={true}>
                                <ListItem>
                                  <ListItemText
                                    primaryTypographyProps={{
                                      style: { fontWeight: "bold" },
                                    }}
                                    primary="Sub Modul Terakhir"
                                    secondary={item?.last_sub_module || "-"}
                                  />
                                </ListItem>
                              </List>
                            </Grid>
                            <Grid item xs={12} md={6} component={Box}>
                              <List dense disablePadding={true}>
                                <ListItem>
                                  <ListItemText
                                    primaryTypographyProps={{
                                      style: { fontWeight: "bold" },
                                    }}
                                    primary="Tipe Sub Modul"
                                    secondary={
                                      subModuletypeConstant[
                                        item?.last_type_sub_module
                                      ] || "-"
                                    }
                                  />
                                </ListItem>
                              </List>
                            </Grid>
                          </Grid>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              );
            })
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
