import {
  Box,
  Chip,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Menu,
} from "@material-ui/icons";
import themeColors from "assets/theme/colors";
import Pagination from "components/Pagination";
import formatCourseType from "helpers/formatCourseType";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const DroppableComponent = onDragEnd => props => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {provided => (
          <TableBody
            innerRef={provided.innerRef}
            {...provided.droppableProps}
            {...props}
          >
            {props.children}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default function CourseSectionDataTable({
  loading,
  data,
  getDetailData,
  handleDeleteData,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
  handleChangeOrder,
}) {
  const [open, setOpen] = useState(false);

  const columns = ["", "#", "Judul", "Tipe", "Opsi", ""];

  const onDragEnd = result => {
    handleChangeOrder(result);
  };

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody component={DroppableComponent(onDragEnd)}>
          {!loading && data.length ? (
            data.map((item, index) => (
              <Draggable
                draggableId={item.id.toString()}
                index={index}
                key={item.id.toString()}
              >
                {provided => (
                  <>
                    <TableRow
                      innerRef={provided.innerRef}
                      {...provided.draggableProps}
                      style={provided.draggableProps.style}
                    >
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            setOpen(prev => ({
                              ...prev,
                              [item.id]: !prev[item.id],
                            }))
                          }
                        >
                          {open[item.id] ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell component="td" scope="row" align="center">
                        {pagination.current_page > 1
                          ? (pagination.current_page - 1) * pagination.limit +
                            (index + 1)
                          : index + 1}
                      </TableCell>
                      <TableCell component="td" align="center">
                        {item.title}
                      </TableCell>
                      <TableCell component="td" align="center">
                        <Chip
                          label={formatCourseType(item.type)}
                          color="primary"
                          size="small"
                        />
                      </TableCell>
                      <TableCell component="td" align="center">
                        <IconButton
                          aria-label="Edit"
                          onClick={() => getDetailData(item.id)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          aria-label="Delete"
                          onClick={() => handleDeleteData(item.id)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton {...provided.dragHandleProps}>
                          <Menu />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={open[item.id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Grid container className="mb-2">
                            <Grid
                              item
                              xs={12}
                              md={12}
                              component={Box}
                              className="pl-4 pt-2"
                            >
                              <Typography
                                variant="h3"
                                gutterBottom
                                component="div"
                              >
                                Detail Data
                              </Typography>
                            </Grid>

                            <Grid item xs={12} md={6} component={Box}>
                              <List dense disablePadding={true}>
                                <ListItem>
                                  <ListItemText
                                    primary="Tipe"
                                    secondary={item?.course_type?.name ?? "-"}
                                  />
                                </ListItem>
                              </List>
                            </Grid>

                            <Grid item xs={12} md={6} component={Box}>
                              <List dense disablePadding={true}>
                                <ListItem>
                                  <ListItemText
                                    primary="kategori"
                                    secondary={
                                      item?.course_category?.name ?? "-"
                                    }
                                  />
                                </ListItem>
                              </List>
                            </Grid>
                          </Grid>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </Draggable>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
