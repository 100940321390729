import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import PopUpAdsDataTable from "./PopUpAdsDataTable";
import api from "services/api";
import http from "services/http";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";

export default function ListPopUpAdsPage() {
  const dispatch = useDispatch();

  const imageRef = useRef(null);

  const { register, setValue, handleSubmit } = useForm({
    defaultValues: {
      pop_up_ads: {
        name: "",
        image: "",
        redirect_url_web: "",
        redirect_url_mobile: "",
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  const [popUpAdsData, setPopUpAdsData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [typeModalPopUpAds, setTypeModalPopUpAds] = useState("CREATE");
  const [showModalPopUpAds, setShowModalPopUpAds] = useState(false);

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(`${api.v1.admin.pop_up_ads.main}`, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setPopUpAdsData(records);

      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.get(`${api.v1.admin.pop_up_ads.main}/${id}`);

      setImageSrc(data.data?.image);

      const { name, redirect_url_web, redirect_url_mobile, active } = data.data;

      fetch(data.data?.image).then(async res => {
        const blob = await res.blob();
        const file = new File([blob], "image.jpg", { type: blob.type });
        setValue("pop_up_ads", {
          id,
          image: [file],
          name,
          redirect_url_web,
          redirect_url_mobile,
          active,
        });
      });

      setTypeModalPopUpAds("UPDATE");
      setShowModalPopUpAds(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { pop_up_ads } = payload;

      const formData = new FormData();
      formData.append("name", pop_up_ads.name || "");
      formData.append("image", pop_up_ads.image[0]);
      formData.append("redirect_url_web", pop_up_ads.redirect_url_web || "");
      formData.append(
        "redirect_url_mobile",
        pop_up_ads.redirect_url_mobile || ""
      );
      formData.append("active", 0);

      await http.post(api.v1.admin.pop_up_ads.main, formData);

      handleCloseModal();

      Swal.fire("Sukses", "Data Pop Up Iklan berhasil di tambahkan", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postActiveData = async (id, active) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.post(`${api.v1.admin.pop_up_ads.main}/${id}/toggle`);

      Swal.fire(
        "Sukses",
        `Promo Code Berhasil ${
          active ? "Ditampilkan" : "Dihilangkan"
        } di Halaman Depan`,
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { pop_up_ads } = payload;

      const formData = new FormData();
      formData.append("name", pop_up_ads.name || "");
      formData.append("image", pop_up_ads.image[0]);
      formData.append("redirect_url_web", pop_up_ads.redirect_url_web || "");
      formData.append(
        "redirect_url_mobile",
        pop_up_ads.redirect_url_mobile || ""
      );
      formData.append("active", pop_up_ads.active);

      await http.put(
        `${api.v1.admin.pop_up_ads.main}/${pop_up_ads.id}`,
        formData
      );

      handleCloseModal();

      Swal.fire("Sukses", "Data Pop Up Iklan berhasil di perbarui", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.pop_up_ads.main}/${id}`);

      Swal.fire("Sukses", "Data Pop Up Iklan di hapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpenModal = () => {
    setTypeModalPopUpAds("CREATE");
    setShowModalPopUpAds(true);
  };

  const handleCloseModal = () => {
    setShowModalPopUpAds(false);
    setImageSrc();
    setValue("pop_up_ads", {
      name: "",
      image: "",
      redirect_url_web: "",
      redirect_url_mobile: "",
      active: 0,
    });
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus pop up iklan",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  const handleActiveData = (id, active) => {
    postActiveData(id, active);
  };

  const registerImageField = register("pop_up_ads.image", {
    required: "Gambar tidak boleh kosong",
  });

  const onUploadImage = () => imageRef.current.click();

  const onImageChange = e => {
    if (e.target.files) {
      setValue("pop_up_ads.image", e.target.files);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Pop Up Iklan</title>
      </Helmet>

      <DefaultHeader section="Data Pop Up Iklan" />

      <Container maxWidth={false} component={Box} id="pop-up-ads">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal()}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <PopUpAdsDataTable
                      loading={loadingData}
                      data={popUpAdsData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      handleActiveData={handleActiveData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Pop Up Ads */}
                <Dialog
                  open={showModalPopUpAds}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Data Pop Up Iklan</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeModalPopUpAds === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        {/* name */}
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Nama</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("pop_up_ads.name")}
                            />
                          </FormGroup>
                        </Grid>

                        {/* image */}
                        <Grid item sm={4} xs={8}>
                          <FormGroup>
                            <FormLabel>Gambar</FormLabel>
                            <Button
                              variant="contained"
                              type="button"
                              onClick={() => onUploadImage()}
                            >
                              {typeModalPopUpAds === "CREATE"
                                ? "Tambah Gambar"
                                : "Ubah Gambar"}
                            </Button>
                            <input
                              style={{ display: "none" }}
                              onChange={e => {
                                registerImageField.onChange(e);
                                onImageChange(e);
                              }}
                              accept="image/*"
                              type="file"
                              ref={imageRef}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item sm={8} xs={4}></Grid>
                        <Grid item sm={12} className="mb-4">
                          {imageSrc && (
                            <Box
                              component="img"
                              sx={{
                                width: 100,
                                maxWidth: { xs: 50, md: 100 },
                              }}
                              alt="Banner."
                              src={imageSrc}
                            />
                          )}
                        </Grid>

                        {/* redirect web  */}
                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel>Redirect Link Web</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("pop_up_ads.redirect_url_web")}
                            />
                          </FormGroup>
                        </Grid>

                        {/* redirect web  */}
                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel>Redirect Link Mobile</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("pop_up_ads.redirect_url_mobile")}
                            />
                          </FormGroup>
                        </Grid>

                        {/* button */}
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalPopUpAds === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
