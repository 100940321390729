import { Helmet } from "react-helmet";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import http from "services/http";
import api from "services/api";
import { baseActions } from "redux/actions";
import Swal from "sweetalert2";
import themeColors from "assets/theme/colors";
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import CourseSectionDataTable from "./CourseSectionDataTable";
import { KeyboardArrowDown } from "@material-ui/icons";
import ReactSelect from "react-select";
import CourseConstant from "constant/CourseConstant";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function ListCourseSection() {
  const dispatch = useDispatch();

  const typeOptions = [
    { value: CourseConstant.COURSE_TYPES.COURSE, label: "Semua Kelas" },
    {
      value: CourseConstant.COURSE_TYPES.COURSE_SELECTED,
      label: "Kelas Pilihan",
    },
  ];

  const orderOptions = [
    { value: "id", label: "ID" },
    { value: "code", label: "Kode" },
    { value: "name", label: "Nama" },
    { value: "price", label: "Harga" },
    { value: "discount_price", label: "Harga Diskon" },
    { value: "created_at", label: "Tanggal Dibuat" },
  ];

  const sortOptions = [
    { value: "ASC", label: "Ascending" },
    { value: "DESC", label: "Descending" },
  ];

  const courseTypeOptions = [
    { value: "ALL", label: "Semua" },
    { value: 1, label: "Prakerja" },
    { value: 2, label: "Umum" },
  ];

  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      course_section: {
        title: "",
        type: "",
        order_by: "",
        sort_by: "",
        course_type_id: "ALL",
        course_category_id: "ALL",
        selected_courses: [],
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [categories, setCategories] = useState([]);
  const [optionCourseData, setOptionCourseData] = useState([]);
  const [courseSectionData, setCourseSectionData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [typeModalCourseSection, setTypeModalCourseSection] =
    useState("CREATE");
  const [showModalCourseSection, setShowModalCourseSection] = useState(false);

  /**
   * Fetch Data
   */

  const getListCategories = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.category, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;

      records.unshift({
        id: "ALL",
        name: "Semua",
      });

      setCategories(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getOptionCourses = async () => {
    try {
      const { data } = await http.get(`${api.v1.admin.course.main}`, {
        params: { option: true },
      });

      setError(null);

      const { records } = data.data;

      const options = records.map(item => {
        return { label: item.name, value: item.id };
      });

      setOptionCourseData(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(`${api.v1.admin.course_section.main}`, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setCourseSectionData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.get(
        `${api.v1.admin.course_section.main}/${id}`
      );

      data.data.selected_courses = data.data.courses.map(item => ({
        label: item.name,
        value: item.id,
      }));
      delete data.data.courses;

      setValue("course_section", {
        id,
        title: data.data.title,
        type: data.data.type,
        order_by: data.data.order_by,
        sort_by: data.data.sort_by,
        course_type_id: data.data.course_type_id,
        course_category_id: data.data.course_category_id,
        selected_courses: data.data.selected_courses,
      });

      setTypeModalCourseSection("UPDATE");
      setShowModalCourseSection(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { course_section } = payload;
      let selected_courses = [];

      if (course_section.type === "COURSE_SELECTED") {
        selected_courses = course_section.selected_courses.map(
          item => item.value
        );
      }

      if (course_section.course_type_id === "ALL")
        course_section.course_type_id = null;

      if (course_section.course_category_id === "ALL")
        course_section.course_category_id = null;

      const body_course_section = {
        ...course_section,
        selected_courses,
      };

      await http.post(api.v1.admin.course_section.main, body_course_section);

      handleCloseModal();
      setShowModalCourseSection(false);

      Swal.fire(
        "Sukses",
        "Data sorotan kelas berhasil di tambahkan",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { course_section } = payload;
      let selected_courses = [];

      if (course_section.type === "COURSE_SELECTED") {
        selected_courses = course_section.selected_courses.map(
          item => item.value
        );
      }

      if (course_section.course_type_id === "ALL")
        course_section.course_type_id = null;

      if (course_section.course_category_id === "ALL")
        course_section.course_category_id = null;

      const body_course_section = {
        ...course_section,
        selected_courses,
      };

      await http.put(
        `${api.v1.admin.course_section.main}/${course_section.id}`,
        body_course_section
      );

      handleCloseModal();
      setShowModalCourseSection(false);

      Swal.fire("Sukses", "Data sorotan kelas berhasil di perbarui", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.course_section.main}/${id}`);

      Swal.fire("Sukses", "Data sorotan kelas berhasil di hapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postChangeOrder = async (to, id) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.post(
        `${api.v1.admin.course_section.main}/${id}/change-order`,
        { to }
      );

      Swal.fire("Sukses", "Urutan sorotan kelas berhasil di ubah", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */

  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpenModal = async () => {
    setTypeModalCourseSection("CREATE");
    setShowModalCourseSection(true);
  };

  const handleCloseModal = () => {
    setShowModalCourseSection(false);
    setValue("course_section", {
      title: "",
      type: "",
      order_by: "",
      sort_by: "",
      course_type_id: null,
      course_category_id: null,
      selected_courses: [],
    });
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data Sorotan Kelas",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  const handleChangeOrder = result => {
    if (!result.destination) return;

    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengubah urutan sorotan kelas",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        const items = reorder(
          courseSectionData,
          result.source.index,
          result.destination.index
        );

        postChangeOrder(result.destination.index, Number(result.draggableId));
        setCourseSectionData(items);
      }
    });
  };

  useEffect(() => {
    getListCategories();
    getOptionCourses();
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Sorotan Kelas</title>
      </Helmet>

      <DefaultHeader section="Data Sorotan Kelas" />

      <Container maxWidth={false} component={Box} id="course-section-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal()}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <CourseSectionDataTable
                      loading={loadingData}
                      data={courseSectionData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                      handleChangeOrder={handleChangeOrder}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Main Slideshow */}
                <Dialog
                  open={showModalCourseSection}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Data Sorotan Kelas</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeModalCourseSection === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormLabel>Judul</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              value={watch("course_section.title")}
                              {...register("course_section.title")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-type-label">
                                Tipe
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                value={watch("course_section.type") || ""}
                                {...register("course_section.type")}
                              >
                                {typeOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-order-label">
                                Urut Berdasarkan
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                value={watch("course_section.order_by") || ""}
                                {...register("course_section.order_by")}
                              >
                                {orderOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-sort-label">
                                Sortir Berdasarkan
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                value={watch("course_section.sort_by") || ""}
                                {...register("course_section.sort_by")}
                              >
                                {sortOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-course-type-label">
                                Tipe Kelas
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                value={
                                  watch("course_section.course_type_id") ||
                                  courseTypeOptions[0].value
                                }
                                {...register("course_section.course_type_id")}
                              >
                                {courseTypeOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-category-label">
                                Kategori Kelas
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                value={
                                  watch("course_section.course_category_id") ||
                                  categories[0].id
                                }
                                {...register(
                                  "course_section.course_category_id"
                                )}
                              >
                                {categories.map((val, key) => (
                                  <MenuItem key={key} value={val.id}>
                                    {val.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        {watch("course_section.type") === "COURSE_SELECTED" && (
                          <>
                            <Grid item xs={12}>
                              <FormGroup>
                                <FormControl variant="outlined" fullWidth>
                                  <FormLabel id="option-status-promo-code-label">
                                    Kelas yang dipilih
                                  </FormLabel>
                                  <ReactSelect
                                    isMulti
                                    options={optionCourseData}
                                    className="basic-multi-select"
                                    classNamePrefix="select-course"
                                    onChange={val =>
                                      setValue(
                                        "course_section.selected_courses",
                                        val
                                      )
                                    }
                                    value={watch(
                                      "course_section.selected_courses"
                                    )}
                                  />
                                </FormControl>
                              </FormGroup>
                            </Grid>
                          </>
                        )}

                        <Grid item sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalCourseSection === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
