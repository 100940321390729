import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import DefaultHeader from "components/Headers/DefaultHeader";
import { Helmet } from "react-helmet";
import componentStyles from "assets/theme/views/admin/elements";
import componentStylesForm from "assets/theme/components/forms";
import componentStylesAvatar from "assets/theme/components/avatar";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesValidation from "assets/theme/views/admin/validation.js";
import { useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { Add, Delete, KeyboardArrowDown } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const useStyles = makeStyles(componentStyles);
const useStylesForm = makeStyles(componentStylesForm);
const useStylesAvatar = makeStyles(componentStylesAvatar);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesValidation = makeStyles(componentStylesValidation);

const DetailInstructorPage = () => {
  const [imageSrc, setImageSrc] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  //   const [instructor, setInstructor] = useState({});

  const avatarRef = useRef(null);
  const imageRef = useRef(null);

  const theme = useTheme();
  const classes = {
    ...useStyles(),
    ...useStylesForm(),
    ...useStylesAvatar(),
    ...useStylesButtons(),
    ...useStylesValidation(),
  };

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      avatar: "",
      description: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "social_media",
    keyName: "key",
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const onEditorStateChange = editorState =>
    setValue("description", editorState);
  const descriptionEditor = watch("description");

  const registerAvatarField = register("avatar", {
    required: "Avatar tidak boleh kosong",
  });
  const onUploadAvatar = () => avatarRef.current.click();
  const onAvatarChange = e => {
    if (e.target.files) {
      setValue("avatar", e.target.files);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  const getInstructor = async id => {
    try {
      setLoadingData(true);

      const { data } = await http.get(`${api.v1.admin.instructors.main}/${id}`);

      const { data: instructorData } = data;

      setImageSrc(instructorData?.avatar);

      fetch(instructorData?.avatar).then(async res => {
        const blob = await res.blob();
        const file = new File([blob], "avatar.jpg", { type: blob.type });
        setValue("avatar", [file]);
      });

      reset({
        name: instructorData?.name,
        description: instructorData?.description,
        email: instructorData?.email,
      });

      instructorData?.social_media?.forEach(item => {
        append({ type: item.type, url: item.url });
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      setLoadingData(false);
    }
  };

  const onSubmit = async values => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("avatar", values.avatar[0]);
      formData.append("description", values.description);

      if (values.social_media) {
        values.social_media.forEach((social, i) => {
          formData.append(`social_media[${i}][type]`, social.type);
          formData.append(`social_media[${i}][url]`, social.url);
        });
      }

      await http.put(`${api.v1.admin.instructors.main}/${id}`, formData);

      Swal.fire("Sukses", "Data instruktur berhasil di perbarui", "success");

      history.push("/instructor");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => {
    register("description", { required: "Deskripsi tidak boleh kosong" });
    getInstructor(id);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Edit Instruktur</title>
      </Helmet>
      <DefaultHeader
        section="Detail Instruktur"
        subsection="Data Instruktur"
        routeBack="/instructor"
      />
      <Container maxWidth={false} component={Box}>
        <Grid container component={Box} justifyContent="center">
          <Grid item xs={12}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    {loadingData ? (
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginTop="1rem"
                        marginBottom="1rem"
                      >
                        <CircularProgress size={24} color="primary" />
                      </Box>
                    ) : (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        {/* Avatar */}
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                        >
                          {errors.avatar && (
                            <FormHelperText
                              component={Box}
                              color={theme.palette.error.main + "!important"}
                              style={{ textAlign: "center" }}
                            >
                              {errors.avatar.message}
                            </FormHelperText>
                          )}
                          <Avatar
                            innerRef={imageRef}
                            src={imageSrc}
                            className={classes.avatarXl}
                          />
                          <Box
                            component={Button}
                            onClick={() => onUploadAvatar()}
                          >
                            Add photo
                          </Box>
                          <input
                            style={{ display: "none" }}
                            onChange={e => {
                              registerAvatarField.onChange(e);
                              onAvatarChange(e);
                            }}
                            accept="image/*"
                            type="file"
                            ref={avatarRef}
                          />
                        </Box>
                        <FormGroup>
                          <FormLabel>Nama</FormLabel>
                          <OutlinedInput
                            fullWidth
                            type="text"
                            {...register("name", {
                              required: "Nama tidak Boleh Kosong",
                            })}
                          />
                          {errors.name && (
                            <FormHelperText
                              component={Box}
                              color={theme.palette.error.main + "!important"}
                            >
                              {errors.name.message}
                            </FormHelperText>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <FormLabel>Email</FormLabel>
                          <OutlinedInput
                            fullWidth
                            type="text"
                            {...register("email")}
                          />
                          {errors.email && (
                            <FormHelperText
                              component={Box}
                              color={theme.palette.error.main + "!important"}
                            >
                              {errors.email.message}
                            </FormHelperText>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <FormLabel>Deskripsi</FormLabel>
                          <ReactQuill
                            value={descriptionEditor}
                            onChange={onEditorStateChange}
                          />
                          {errors.description && (
                            <FormHelperText
                              component={Box}
                              color={theme.palette.error.main + "!important"}
                            >
                              {errors.description.message}
                            </FormHelperText>
                          )}
                        </FormGroup>
                        <Box display="flex">
                          <Box component={Typography} variant="h3">
                            Media Sosial
                          </Box>
                          <Box marginLeft="5px!important">
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                append({
                                  type: "",
                                  url: "",
                                });
                              }}
                            >
                              <Add size="large" />
                            </Button>
                          </Box>
                        </Box>
                        {fields.map((field, index) => (
                          <Grid container key={field.key}>
                            <Grid item xs={12} md={3}>
                              <FormGroup>
                                <FormLabel>Tipe</FormLabel>
                                <FormControl variant="outlined" fullWidth>
                                  <Controller
                                    control={control}
                                    name={`social_media.${index}.type`}
                                    render={({ field: { onChange } }) => (
                                      <Select
                                        IconComponent={KeyboardArrowDown}
                                        defaultValue={field.type || ""}
                                        onChange={onChange}
                                      >
                                        <MenuItem value="INSTAGRAM">
                                          Instagram
                                        </MenuItem>
                                        <MenuItem value="LINKEDIN">
                                          LinkedIn
                                        </MenuItem>
                                      </Select>
                                    )}
                                  ></Controller>
                                </FormControl>
                              </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={9}>
                              <FormGroup>
                                <FormLabel>Link</FormLabel>
                                <Box display="flex">
                                  <OutlinedInput
                                    fullWidth
                                    type="text"
                                    {...register(`social_media.${index}.url`)}
                                  />
                                  <Box
                                    marginLeft="5px"
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Button
                                      variant="contained"
                                      size="small"
                                      classes={{
                                        root: classes.buttonContainedError,
                                      }}
                                      onClick={() => remove(index)}
                                    >
                                      <Delete size="large" />
                                    </Button>
                                  </Box>
                                </Box>
                              </FormGroup>
                            </Grid>
                          </Grid>
                        ))}
                        <Grid container className="mt-5">
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              type="submit"
                            >
                              Perbarui
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default DetailInstructorPage;
