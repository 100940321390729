import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
  Switch,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";

import themeColors from "assets/theme/colors";

import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";
import MainSlideshowDataTable from "./MainSlideshowDataTable";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function ListMainSlideshow() {
  const dispatch = useDispatch();
  const bannerRef = useRef(null);
  const { register, setValue, handleSubmit } = useForm({
    defaultValues: {
      main_slideshow: {
        banner: "",
        publish_at: moment().format("YYYY-MM-DDTHH:mm"),
        redirect_url: "",
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [toggleActive, setToggleActive] = useState(false);

  const [mainSlideshowData, setMainSlideshowData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [typeModalMainSlideshow, setTypeModalMainSlideshow] =
    useState("CREATE");
  const [showModalMainSlideshow, setShowModalMainSlideshow] = useState(false);

  /**
   * Fetch Data
   */

  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(`${api.v1.admin.main_slideshow.main}`, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setMainSlideshowData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.get(
        `${api.v1.admin.main_slideshow.main}/${id}`
      );

      setImageSrc(data.data?.image);
      fetch(data.data?.image).then(async res => {
        const blob = await res.blob();
        const file = new File([blob], "banner.jpg", { type: blob.type });
        setValue("main_slideshow", {
          id,
          banner: [file],
          publish_at: data.data.publish_at
            ? moment(data.data.publish_at).format("YYYY-MM-DDTHH:mm")
            : moment().format("YYYY-MM-DDTHH:mm"),
          redirect_url: data.data.redirect_url,
        });
      });

      data.data.publish_at ? setToggleActive(true) : setToggleActive(false);
      setTypeModalMainSlideshow("UPDATE");
      setShowModalMainSlideshow(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { main_slideshow } = payload;

      const formData = new FormData();
      formData.append("image", main_slideshow.banner[0]);
      formData.append("redirect_url", main_slideshow.redirect_url || "");
      toggleActive
        ? formData.append("publish_at", moment(main_slideshow.publish_at))
        : formData.append("publish_at", "");

      await http.post(api.v1.admin.main_slideshow.main, formData);

      handleCloseModal();

      Swal.fire("Sukses", "Data Banner Utama berhasil di tambahkan", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { main_slideshow } = payload;

      const formData = new FormData();
      formData.append("image", main_slideshow.banner[0]);
      formData.append("redirect_url", main_slideshow.redirect_url || "");
      toggleActive
        ? formData.append("publish_at", moment(main_slideshow.publish_at))
        : formData.append("publish_at", "");

      await http.put(
        `${api.v1.admin.main_slideshow.main}/${main_slideshow.id}`,
        formData
      );

      handleCloseModal();

      Swal.fire("Sukses", "Data Banner Utama berhasil di perbarui", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.main_slideshow.main}/${id}`);

      Swal.fire("Sukses", "Data Banner Utama berhasil di hapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postChangeOrder = async (to, id) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.post(
        `${api.v1.admin.main_slideshow.main}/${id}/change-order`,
        { to }
      );

      Swal.fire("Sukses", "Urutan banner utama berhasil di ubah", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpenModal = async () => {
    setTypeModalMainSlideshow("CREATE");
    setShowModalMainSlideshow(true);
    setToggleActive(false);
    setImageSrc();
  };

  const handleCloseModal = () => {
    setShowModalMainSlideshow(false);
    setImageSrc();
    setToggleActive(false);
    setValue("main_slideshow", {
      banner: "",
      publish_at: moment().format("YYYY-MM-DDTHH:mm"),
      redirect_url: "",
    });
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data Banner Utama",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  const handleChangeOrder = result => {
    if (!result.destination) return;

    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengubah urutan banner utama",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        const items = reorder(
          mainSlideshowData,
          result.source.index,
          result.destination.index
        );

        postChangeOrder(result.destination.index, Number(result.draggableId));
        setMainSlideshowData(items);
      }
    });
  };

  const registerBannerField = register("main_slideshow.banner", {
    required: "Cover tidak boleh kosong",
  });
  const onUploadBanner = () => bannerRef.current.click();
  const onBannerChange = e => {
    if (e.target.files) {
      setValue("main_slideshow.banner", e.target.files);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Banner Utama</title>
      </Helmet>

      <DefaultHeader section="Data Banner Utama" />

      <Container maxWidth={false} component={Box} id="main-slideshow-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal()}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <MainSlideshowDataTable
                      loading={loadingData}
                      data={mainSlideshowData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                      handleChangeOrder={handleChangeOrder}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Main Slideshow */}
                <Dialog
                  open={showModalMainSlideshow}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Data Banner Utama</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeModalMainSlideshow === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        <Grid item sm={4} xs={8}>
                          <FormGroup>
                            <FormLabel>Banner</FormLabel>
                            <Button
                              variant="contained"
                              onClick={() => onUploadBanner()}
                            >
                              {typeModalMainSlideshow === "CREATE"
                                ? "Tambah Banner"
                                : "Ubah Banner"}
                            </Button>
                            <input
                              style={{ display: "none" }}
                              onChange={e => {
                                registerBannerField.onChange(e);
                                onBannerChange(e);
                              }}
                              accept="image/*"
                              type="file"
                              ref={bannerRef}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item sm={8} xs={4}></Grid>
                        <Grid item sm={12} className="mb-4">
                          {imageSrc && (
                            <Box
                              component="img"
                              sx={{
                                height: 240,
                                width: 900,
                                maxHeight: { xs: 150, md: 240 },
                                maxWidth: { xs: 350, md: 900 },
                                borderRadius: "16px",
                              }}
                              alt="Banner."
                              src={imageSrc}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Link</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("main_slideshow.redirect_url")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <FormGroup>
                            <FormLabel>Aktif</FormLabel>
                            <Switch
                              color="primary"
                              checked={toggleActive}
                              onChange={e => setToggleActive(e.target.checked)}
                            />
                          </FormGroup>
                        </Grid>
                        {toggleActive && (
                          <Grid item sm={12}>
                            <FormGroup>
                              <FormLabel>Tanggal Publish</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="datetime-local"
                                {...register("main_slideshow.publish_at")}
                              />
                            </FormGroup>
                          </Grid>
                        )}

                        <Grid item sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalMainSlideshow === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
