import CourseConstant from "constant/CourseConstant";

const formatCourseType = string => {
  switch (string) {
    case CourseConstant.COURSE_TYPES.COURSE:
      return "Semua Kelas";
    case CourseConstant.COURSE_TYPES.COURSE_SELECTED:
      return "Kelas Pilihan";
    default:
      return "-";
  }
};

export default formatCourseType;
