import React from "react";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import reportWebVitals from "./reportWebVitals";

import theme from "./assets/theme/theme";
import store from "redux/store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import App from "./App";

// plugins styles from node_modules
import "react-perfect-scrollbar/dist/css/styles.css";
// import "quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/plugins/nucleo/css/nucleo.css";
// import "sweetalert2/src/sweetalert2.scss";
// core styles
import "assets/scss/argon-dashboard-pro-material-ui.scss?v1.0.0";

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
