import React, { useEffect, useState } from "react";

import {
  Box,
  Card,
  Container,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
  Switch,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  IconButton,
  makeStyles,
  SnackbarContent,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";

import theme from "assets/theme/theme";
import snackbarTheme from "assets/theme/components/snackbar.js";

import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";

import DefaultHeader from "components/Headers/DefaultHeader";

const snackbarStyles = makeStyles(snackbarTheme);

export default function CreateCoursePage() {
  const styles = { ...snackbarStyles() };
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, watch, setValue } = useForm({
    defaultValues: {
      course: {
        name: "",
        slug: "",
      },
    },
  });

  const [error, setError] = useState(null);

  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [levels, setLevels] = useState([]);
  const [teachMethods, setTeachMethods] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [applePrice, setApplePrice] = useState([]);
  const [instructorsList, setInstructorsList] = useState([]);
  const [assessors, setAssessors] = useState([]);
  const [instructorAssistances, setInstructorAssistances] = useState([]);
  const [assistanceInstructorsList, setAssistanceInstructorsList] = useState(
    []
  );
  const [partners, setPartners] = useState([]);

  const [toggleCertificate, setToggleCertificate] = useState(false);
  const [toggleExternalPurcahse, setToggleExternalPurcahse] = useState(false);

  const [coursePartner, setCoursePartner] = useState({});

  const statusCourseOptions = ["DRAFT", "QC", "PUBLISH"];

  /**
   * Fetch Data
   */
  const getListCategories = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.category, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;

      setCategories(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListTypes = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.type, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;

      setTypes(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListLevels = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.level, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;

      setLevels(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListTeachMethods = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.teach_method, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;

      setTeachMethods(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListInstructors = async () => {
    try {
      const { data } = await http.get(api.v1.admin.instructors.main, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;

      setInstructors(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListApplePrice = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.apple_price, {
        params: { option: "true", all: true },
      });

      setError(null);

      let { records } = data.data;

      setApplePrice(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListAssessor = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.assessor, {
        params: { limit: 999 },
      });

      setError(null);

      let { records } = data.data;

      setAssessors(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListPartners = async () => {
    try {
      const { data } = await http.get(api.v1.admin.partner.main, {
        params: { limit: 999, option: true, all: true },
      });

      setError(null);

      let { records } = data.data;

      setPartners(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListInstrucotrAssistances = async () => {
    try {
      const { data } = await http.get(api.v1.admin.instructor_assistance.main, {
        params: { option: true, all: true },
      });

      setError(null);

      let { records } = data.data;

      setInstructorAssistances(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const postCreateCourse = async () => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const payload = watch("course");

      const courseAssessor = watch("course.course_assessor");

      payload.publish_at = moment(payload.publish_at).toISOString();
      payload.certificate = toggleCertificate ? 1 : 0;
      payload.external_purchase = toggleExternalPurcahse ? 1 : 0;

      if (!payload.certificate) {
        delete payload.course_assessor_id;
        delete payload.course_assessor;
      }

      const formData = new FormData();
      const files = ["cover", "certificate_sample"];

      for (const key in payload) {
        if (!Array.isArray(payload[key]) && !files.includes(key))
          formData.append(key, payload[key]);

        if (files.includes(key)) formData.append(key, payload[key][0]);
      }

      if (instructorsList.length)
        instructorsList.forEach((instList, key) => {
          formData.append(`instructors[${key}]`, instList);
        });

      if (assistanceInstructorsList?.length)
        assistanceInstructorsList.forEach((instList, key) => {
          formData.append(`instructor_assistances[${key}]`, instList);
        });

      if (payload.certificate && courseAssessor) {
        formData.append(
          "course_assessor_id",
          courseAssessor?.id ? parseInt(courseAssessor?.id) : null
        );
      }

      if (coursePartner?.id) {
        formData.append(
          "partner_id",
          coursePartner?.id ? parseInt(coursePartner?.id) : null
        );
      }

      await http.post(api.v1.admin.course.main, formData);

      Swal.fire("Sukses", "Data kelas berhasil di tambahkan", "success");

      history.push("/kelas");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */

  const handlechangeInput = e => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    if (name === "name") {
      setValue("course.name", value);
      setValue("course.slug", value.replace(/ /g, "-").toLowerCase());
    } else if (name === "slug") {
      setValue("course.slug", value.replace(/ /g, "-").toLowerCase());
    } else {
      setValue(`course.${name}`, value);
    }
  };

  useEffect(() => {
    getListCategories();
    getListTypes();
    getListLevels();
    getListTeachMethods();
    getListInstructors();
    getListApplePrice();
    getListAssessor();
    getListPartners();
    getListInstrucotrAssistances();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Tambah Kelas</title>
      </Helmet>

      <DefaultHeader
        section="Tambah Kelas"
        subsection="Data Kelas"
        routeBack="/kelas"
      />

      <Container maxWidth={false} component={Box} id="create-course-page">
        <Grid container>
          <Grid item xs={12}>
            <Card className="p-4">
              {error && (
                <Grid container>
                  <Grid item xs={12} className="mb-2">
                    <SnackbarContent
                      classes={{ root: styles.errorSnackbar }}
                      action={
                        <Box
                          component={IconButton}
                          padding="0!important"
                          onClick={() => setError(null)}
                        >
                          <Box
                            component="span"
                            color={theme.palette.white.main}
                          >
                            ×
                          </Box>
                        </Box>
                      }
                      message={<Box>{error}</Box>}
                    />
                  </Grid>
                </Grid>
              )}

              {/* Master Data */}
              <Grid container>
                <Grid item xs={12} className="mb-2">
                  <h2 className="text-muted">Master Data</h2>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Kode</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="text"
                      {...register("course.code")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={9}>
                  <FormGroup>
                    <FormLabel>Judul</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="text"
                      name="name"
                      onChange={handlechangeInput}
                      value={watch("course.name")}
                      // {...register("course.name")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <FormLabel>Slug</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="text"
                      name="slug"
                      onChange={handlechangeInput}
                      value={watch("course.slug")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <FormLabel>Deskripsi</FormLabel>
                    <ReactQuill
                      theme="snow"
                      onChange={val => setValue("course.description", val)}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Kategori</FormLabel>
                    <Autocomplete
                      id="combo-box-category"
                      options={categories}
                      getOptionLabel={option => option.name}
                      closeIcon={null}
                      onInputChange={(e, val) => {
                        const { id } = categories.filter(
                          data => data.name === val
                        )[0];

                        setValue("course.course_category_id", id);
                      }}
                      popupIcon={
                        <Box
                          component={KeyboardArrowDown}
                          width="1.25rem!important"
                          height="1.25rem!important"
                        />
                      }
                      renderInput={params => (
                        <TextField {...params} variant="filled" />
                      )}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Tipe</FormLabel>
                    <Autocomplete
                      id="combo-box-category"
                      options={types}
                      getOptionLabel={option => option.name}
                      closeIcon={null}
                      onInputChange={(e, val) => {
                        const { id } = types.filter(
                          data => data.name === val
                        )[0];

                        setValue("course.course_type_id", id);
                      }}
                      popupIcon={
                        <Box
                          component={KeyboardArrowDown}
                          width="1.25rem!important"
                          height="1.25rem!important"
                        />
                      }
                      renderInput={params => (
                        <TextField {...params} variant="filled" />
                      )}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Level</FormLabel>
                    <Autocomplete
                      id="combo-box-category"
                      options={levels}
                      getOptionLabel={option => option.name}
                      closeIcon={null}
                      onInputChange={(e, val) => {
                        const { id } = levels.filter(
                          data => data.name === val
                        )[0];

                        setValue("course.course_level_id", id);
                      }}
                      popupIcon={
                        <Box
                          component={KeyboardArrowDown}
                          width="1.25rem!important"
                          height="1.25rem!important"
                        />
                      }
                      renderInput={params => (
                        <TextField {...params} variant="filled" />
                      )}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Metode Ajar</FormLabel>
                    <Autocomplete
                      id="combo-box-category"
                      options={teachMethods}
                      getOptionLabel={option => option.name}
                      closeIcon={null}
                      onInputChange={(e, val) => {
                        const { id } = teachMethods.filter(
                          data => data.name === val
                        )[0];

                        setValue("teach_method", val);
                        setValue("course.course_teach_method_id", id);
                      }}
                      popupIcon={
                        <Box
                          component={KeyboardArrowDown}
                          width="1.25rem!important"
                          height="1.25rem!important"
                        />
                      }
                      renderInput={params => (
                        <TextField {...params} variant="filled" />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormLabel>Gambar Kelas</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="file"
                      placeholder="Default input"
                      {...register("course.cover")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormLabel>Instruktur</FormLabel>
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="combo-box-category"
                      options={instructors}
                      getOptionLabel={option => option.name}
                      closeIcon={null}
                      onChange={(e, val) => {
                        if (instructors.length)
                          setInstructorsList(val.map(el => parseInt(el.id)));
                      }}
                      popupIcon={
                        <Box
                          component={KeyboardArrowDown}
                          width="1.25rem!important"
                          height="1.25rem!important"
                        />
                      }
                      renderInput={params => (
                        <TextField {...params} variant="filled" />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormLabel>Asisten Instruktur</FormLabel>
                    <Autocomplete
                      multiple
                      id="combo-box-instructor-assistance"
                      options={instructorAssistances}
                      getOptionLabel={option => option.name}
                      closeIcon={null}
                      onChange={(e, val) => {
                        if (instructorAssistances.length) {
                          setAssistanceInstructorsList(
                            val.map(el => parseInt(el.id))
                          );
                        }
                      }}
                      popupIcon={
                        <Box
                          component={KeyboardArrowDown}
                          width="1.25rem!important"
                          height="1.25rem!important"
                        />
                      }
                      renderInput={params => (
                        <TextField {...params} variant="filled" />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormLabel>Durasi</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="number"
                      {...register("course.duration")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormLabel>Bahasa</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="text"
                      {...register("course.language")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormLabel>Harga</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="number"
                      {...register("course.price")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Harga Diskon</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="number"
                      {...register("course.discount_price")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Apple Pay</FormLabel>
                    <Autocomplete
                      id="combo-box-category"
                      options={applePrice}
                      getOptionLabel={option => option.price}
                      closeIcon={null}
                      onInputChange={(e, val) => {
                        const { id } = applePrice.filter(
                          data => data.price === val
                        )[0];

                        setValue("course_apple_price", val);
                        setValue("course.course_apple_price_id", id);
                      }}
                      popupIcon={
                        <Box
                          component={KeyboardArrowDown}
                          width="1.25rem!important"
                          height="1.25rem!important"
                        />
                      }
                      renderInput={params => (
                        <TextField {...params} variant="filled" />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Pratinjau Video</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="text"
                      {...register("course.preview_video")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormGroup>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="option-competence-aspect-label">
                        Status
                      </FormLabel>
                      <Select
                        IconComponent={KeyboardArrowDown}
                        labelId="option-competence-aspect-label"
                        value={watch("course.status") || ""}
                        {...register("course.status")}
                      >
                        {statusCourseOptions.map((val, key) => (
                          <MenuItem key={key} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Tanggal Publish</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="datetime-local"
                      defaultValue={moment().format("yyyy-MM-DDTHH:mm:ss")}
                      {...register("course.publish_at")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Total Peserta</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="number"
                      {...register("course.total_participant")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Kode Prakerja</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="text"
                      {...register("course.prakerja_code")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Link Grup Diskusi</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="text"
                      {...register("course.group_url")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <FormLabel>Kelompok Sasaran</FormLabel>
                    <ReactQuill
                      theme="snow"
                      onChange={val => setValue("course.target_group", val)}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Partner</FormLabel>
                    <Autocomplete
                      id="combo-box-partner"
                      options={partners}
                      getOptionLabel={option => option.name}
                      defaultValue={coursePartner}
                      getOptionSelected={(option, val) => option.id === val.id}
                      onChange={(e, val) => {
                        setCoursePartner(val);
                      }}
                      popupIcon={
                        <Box
                          component={KeyboardArrowDown}
                          width="1.25rem!important"
                          height="1.25rem!important"
                        />
                      }
                      renderInput={params => (
                        <TextField {...params} variant="filled" />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <FormLabel>Sertifikat</FormLabel>
                    <Switch
                      color="primary"
                      size="medium"
                      defaultChecked={toggleCertificate}
                      onChange={e => {
                        setToggleCertificate(e.target.checked);
                      }}
                    />
                  </FormGroup>
                </Grid>

                {toggleCertificate ? (
                  <Grid item xs={12} md={3}>
                    <FormGroup>
                      <FormLabel>Asesor</FormLabel>
                      <Autocomplete
                        id="combo-box-asesor"
                        options={assessors}
                        getOptionLabel={option => option.name}
                        defaultValue={watch("course.course_assessor")}
                        getOptionSelected={(option, val) =>
                          option.id === val.id
                        }
                        onChange={(e, val) => {
                          setValue("course.course_assessor", val);
                        }}
                        popupIcon={
                          <Box
                            component={KeyboardArrowDown}
                            width="1.25rem!important"
                            height="1.25rem!important"
                          />
                        }
                        renderInput={params => (
                          <TextField {...params} variant="filled" />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                ) : null}
              </Grid>

              {/* External purcahse */}
              <Grid container className="mt-4">
                <Grid item xs={12} className="mb-2">
                  <h2 className="text-muted">
                    Pembelian Eksternal
                    <Switch
                      color="primary"
                      size="medium"
                      checked={toggleExternalPurcahse}
                      onChange={e =>
                        setToggleExternalPurcahse(e.target.checked)
                      }
                      className="ml-2"
                    />
                  </h2>
                </Grid>

                {toggleExternalPurcahse && (
                  <Grid item xs={12}>
                    <FormGroup>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        placeholder="Link Pembelian Eksternal"
                        {...register("course.external_purchase_url")}
                      />
                    </FormGroup>
                  </Grid>
                )}
              </Grid>

              <Grid container className="mt-5">
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={postCreateCourse}
                  >
                    Simpan
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
