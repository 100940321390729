import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  OutlinedInput,
  Switch,
  TextField,
  useTheme,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";

import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";

export default function GenerateVoucherPage() {
  const [loadingData, setLoadingData] = useState(false);
  const [courses, setCourses] = useState([]);
  const [voucherTypes, setVoucherTypes] = useState([]);
  const [toggleRedeemption, setToggleRedeemption] = useState(true);
  const [scheduleOptions, setScheduleOptions] = useState([]);

  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      totalVoucher: 0,
      course: "",
      voucher: "",
      withSchedule: false,
    },
  });

  const getVoucherNeeds = async () => {
    setLoadingData(true);

    const getCourses = http.get(api.v1.admin.course.main, {
      params: { option: "true" },
    });

    const getVoucherTypes = http.get(api.v1.admin.voucher.type, {
      params: { option: "true" },
    });

    const [courses, voucherTypes] = await Promise.all([
      getCourses,
      getVoucherTypes,
    ]);

    const { data: courseResponse } = courses.data;
    const { records: courseRecords } = courseResponse;
    const { data: voucherTypeResponse } = voucherTypes.data;
    const { records: voucherTypeRecords } = voucherTypeResponse;

    setCourses(courseRecords);
    setVoucherTypes(voucherTypeRecords);

    setLoadingData(false);
  };

  const getWebinarSchedule = async courseId => {
    const { data } = await http.get(
      `${api.v1.admin.course.main}/${courseId}/webinar`,
      {
        params: { limit: 100, page: 1 },
      }
    );

    const scheduleOptions = [];
    data.data.records.forEach(el => {
      if (el.sessions.length) {
        const label = `${moment(el?.sessions[0].date).format(
          "DD/MM/YYYY"
        )} - ${moment(el?.sessions[el?.sessions.length - 1].date).format(
          "DD/MM/YYYY"
        )} - ${el.code}`;

        scheduleOptions.push({
          id: el.id,
          label,
        });
      }
    });

    setScheduleOptions(scheduleOptions);
  };

  const getOfflineSchedule = async courseId => {
    const { data } = await http.get(
      `${api.v1.admin.course.main}/${courseId}/offline-schedule`,
      {
        params: { limit: 100, page: 1 },
      }
    );

    const scheduleOptions = [];

    data.data.records.forEach(el => {
      if (el?.course_offline_schedule_sessions.length) {
        const label = `${moment(
          el?.course_offline_schedule_sessions[0].date
        ).format("DD/MM/YYYY")} - ${moment(
          el?.course_offline_schedule_sessions[
            el?.course_offline_schedule_sessions?.length - 1
          ].date
        ).format("DD/MM/YYYY")} - ${el.code}`;

        scheduleOptions.push({
          id: el.id,
          label,
        });
      }
    });

    setScheduleOptions(scheduleOptions);
  };

  const onSubmit = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { totalVoucher, course, voucher, expired_date, schedule } = val;

      const courseWebinarId =
        watch("withSchedule") && course?.course_teach_method_id === 1
          ? schedule.id
          : null;
      const courseOfflineScheduleId =
        watch("withSchedule") && course?.course_teach_method_id === 4
          ? schedule.id
          : null;

      const res = await http.post(
        `${api.v1.admin.voucher.main}/generate`,
        {
          count: parseInt(totalVoucher),
          course_id: course.id,
          course_voucher_type_id: voucher.id,
          expired_date,
          has_redeemption_code: toggleRedeemption ? 1 : 0,
          course_webinar_id: courseWebinarId,
          course_offline_schedule_id: courseOfflineScheduleId,
        },
        { responseType: "blob" }
      );

      const exportDate = moment().format("D MMMM YYYY hh:mm:ss");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - Voucher - ${course.name} - ${course.code}.xlsx`;

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);

      Swal.fire("Sukses", "Voucher berhasil dibuat", "success");

      reset();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => getVoucherNeeds(), []);

  return (
    <>
      {/* Buat Voucher */}
      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    {loadingData ? (
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginTop="1rem"
                        marginBottom="1rem"
                      >
                        <CircularProgress size={24} color="primary" />
                      </Box>
                    ) : (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                          <Grid item sm={4}>
                            <FormGroup>
                              <FormLabel>Jumlah Voucher</FormLabel>
                              <OutlinedInput
                                type="number"
                                {...register("totalVoucher", {
                                  min: 1,
                                })}
                              />
                              {errors.totalVoucher && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  Tidak boleh kosong
                                </FormHelperText>
                              )}
                            </FormGroup>
                          </Grid>

                          <Grid item sm={4}>
                            <FormGroup>
                              <FormLabel>Tipe Voucher</FormLabel>
                              <Controller
                                control={control}
                                name="voucher"
                                rules={{
                                  required: "Tidak boleh kosong",
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    options={voucherTypes}
                                    getOptionLabel={option => option.name || ""}
                                    getOptionSelected={(option, val) =>
                                      option.id === val.id
                                    }
                                    closeIcon={null}
                                    value={value}
                                    onChange={(e, val) => {
                                      onChange(val);
                                    }}
                                    popupIcon={
                                      <Box
                                        component={KeyboardArrowDown}
                                        width="1.25rem!important"
                                        height="1.25rem!important"
                                      />
                                    }
                                    renderInput={params => (
                                      <TextField {...params} variant="filled" />
                                    )}
                                  />
                                )}
                              />
                              {errors.voucher && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.course.message}
                                </FormHelperText>
                              )}
                            </FormGroup>
                          </Grid>

                          <Grid item sm={4}>
                            <FormGroup>
                              <FormLabel>Tanggal Kadaluarsa</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="datetime-local"
                                defaultValue={moment()
                                  .endOf("year")
                                  .set({ D: "25" })
                                  .format("yyyy-MM-DDThh:mm:ss")}
                                {...register("expired_date")}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item sm={12}>
                            <FormGroup>
                              <FormLabel>Pilih Kelas</FormLabel>
                              <Controller
                                control={control}
                                name="course"
                                rules={{
                                  required: "Tidak boleh kosong",
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    options={courses}
                                    getOptionLabel={option =>
                                      option?.name
                                        ? `${option?.name} - ${option?.code}`
                                        : ""
                                    }
                                    getOptionSelected={(option, val) =>
                                      val === undefined ||
                                      val === "" ||
                                      option.id === val.id
                                    }
                                    closeIcon={null}
                                    value={value}
                                    onChange={(e, val) => {
                                      onChange(val);

                                      if (val?.course_teach_method_id === 1)
                                        getWebinarSchedule(val.id);
                                      if (val?.course_teach_method_id === 4)
                                        getOfflineSchedule(val.id);
                                    }}
                                    popupIcon={
                                      <Box
                                        component={KeyboardArrowDown}
                                        width="1.25rem!important"
                                        height="1.25rem!important"
                                      />
                                    }
                                    renderInput={params => (
                                      <TextField {...params} variant="filled" />
                                    )}
                                  />
                                )}
                              />
                              {errors.course && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.course.message}
                                </FormHelperText>
                              )}
                            </FormGroup>
                          </Grid>

                          {[1, 4].includes(
                            watch("course")?.course_teach_method_id
                          ) && (
                            <Grid item sm={12}>
                              <FormGroup>
                                <FormLabel>
                                  <Checkbox
                                    checked={watch("withSchedule")}
                                    onChange={() =>
                                      setValue(
                                        "withSchedule",
                                        !watch("withSchedule")
                                      )
                                    }
                                    color="primary"
                                  />
                                  Pilih Jadwal
                                </FormLabel>
                                {watch("withSchedule") === true ? (
                                  <Controller
                                    control={control}
                                    name="schedule"
                                    rules={{
                                      required: "Tidak boleh kosong",
                                    }}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <Autocomplete
                                        options={scheduleOptions}
                                        getOptionLabel={option =>
                                          option ? option.label : ""
                                        }
                                        getOptionSelected={(option, val) =>
                                          val === undefined ||
                                          val === "" ||
                                          option.id === val.id
                                        }
                                        closeIcon={null}
                                        value={value}
                                        onChange={(e, val) => {
                                          onChange(val);
                                        }}
                                        popupIcon={
                                          <Box
                                            component={KeyboardArrowDown}
                                            width="1.25rem!important"
                                            height="1.25rem!important"
                                          />
                                        }
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            variant="filled"
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                ) : null}

                                {errors.course && (
                                  <FormHelperText
                                    component={Box}
                                    color={
                                      theme.palette.error.main + "!important"
                                    }
                                  >
                                    {errors.course.message}
                                  </FormHelperText>
                                )}
                              </FormGroup>
                            </Grid>
                          )}

                          <Grid item sm={12}>
                            <FormGroup>
                              <FormLabel>Kode Redeem</FormLabel>
                              <Switch
                                color="primary"
                                size="medium"
                                checked={toggleRedeemption}
                                onChange={e =>
                                  setToggleRedeemption(e.target.checked)
                                }
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              type="submit"
                            >
                              Generate Voucher
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
